import styled from "styled-components";

interface ButtonStyleProps {
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  height?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  bgcolor?: string;
  fontcolor?: string;
  fontsize?: number;
  borderradius?: string;
  align?: string;
  margint?: number;
  marginb?: number;
  marginl?: number;
  marginr?: number | string;
  padding?: number;
  border?: string;
  opacity?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disableHover?: boolean;
}

const Button = styled.button<ButtonStyleProps>`
  width: ${({ width }) =>
    typeof width === "number" ? `${width}px` : width || "204px"};
  min-width: ${({ minWidth }) =>
    typeof minWidth === "number" ? `${minWidth}px` : minWidth || ""};
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth || ""};
  height: ${({ height }) =>
    typeof height === "number" ? `${height}px` : height || "38px"};
  min-height: ${({ minHeight }) =>
    typeof minHeight === "number" ? `${minHeight}px` : minHeight || ""};
  max-height: ${({ maxHeight }) =>
    typeof maxHeight === "number" ? `${maxHeight}px` : maxHeight || ""};
  opacity: ${({ opacity }) => opacity || 1};
  background-color: ${({ bgcolor }) => bgcolor || "#585858"};
  color: ${({ fontcolor }) => fontcolor || "#fff"};
  border: ${({ border }) => border || "none"};
  border-radius: ${({ borderradius }) => borderradius || "999px"};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || "center"};
  font-size: ${({ fontsize }) => fontsize || 12}px;
  margin-top: ${({ margint }) => margint || 0}px;
  margin-bottom: ${({ marginb }) => marginb || 0}px;
  margin-right: ${({ marginr }) =>
    typeof marginr === "number" ? `${marginr}px` : marginr || 0};
  margin-left: ${({ marginl }) => marginl || 0}px;
  padding: ${({ padding }) => padding || 0}px;

  ${(props) =>
    props.disableHover !== true &&
    `
    &:hover {
      background-color: ${props.bgcolor ? darken(props.bgcolor, 0.1) : "#484848"};
      color: ${props.fontcolor || "#fff"};
    }
  `}
`;

/* 색상을 어둡게 만드는 유틸리티 함수 */
function darken(color: string, percentage: number) {
  const decimal = percentage * 100;
  return `#${color
    .replace(/^#/, "")
    .replace(/../g, (color) =>
      (
        "0" +
        Math.min(255, Math.max(0, parseInt(color, 16) - decimal)).toString(16)
      ).substr(-2)
    )}`;
}

export default Button;
