import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useSetRecoilState } from "recoil";
import { modalState } from "../recoil/modalState";
import { useAPI } from "./API";  // API 훅 추가

interface WrapperProps {
  height?: string;
  fontSize?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: ${(props) => props.height || '300px'};
  min-height: 50px;
  border: 1px solid black;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.fontSize || '16px'};
  position: relative;
  overflow: visible;
  z-index: 1;
`;

const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  z-index: 2;
`;

const IndexNumber = styled.div`
  color: #4e4e59;
  font-size: 16px;
  font-weight: bold;
`;

const FavoriteIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #4e4e59;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #f44336;
    transform: scale(1.1);
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;  // 이 부분을 추가하여 내부 스크롤을 가능하게 합니다.
  justify-content: space-between;
`;

const Question = styled.div<WrapperProps>`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: ${(props) => props.fontSize || 'inherit'};
  padding: 40px 10px 10px;
  text-align: center;
  overflow-y: auto;  // 세로 스크롤을 추가합니다.
`;

const Answer = styled.div<WrapperProps>`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontSize || 'inherit'};
  padding: 10px;
  text-align: center;
  overflow-y: auto;  // 세로 스크롤을 추가합니다.
`;

const AnswerWrapper = styled.div`
  color: red;
`;

const PronunciationWrapper = styled.div`
  color: #808080;
  font-size: 1em;
  margin-top: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
`;

const AnswerButton = styled.button<{ radius: string; backgroundColor: string; color: string }>`
  width: 50%;
  height: 100%;
  border-radius: ${(props) => props.radius};
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => 
      props.backgroundColor === '#F44336' ? '#D32F2F' : 
      props.backgroundColor === '#4CAF50' ? '#388E3C' : 
      props.backgroundColor};
  }
`;

const ButtonText = styled.span`
  font-size: 18px;
  color: inherit;
  margin-bottom: 5px;
`;

const KeyText = styled.span`
  font-size: 12px;
  opacity: 0.8;
  color: inherit;
`;

const InfoWrapper = styled.div`
  color: #808080;
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoItem = styled.span`
  margin: 0 5px;
`;

const Separator = styled.span`
  margin: 0 5px;
`;

interface WrapperProps {
  height?: string;
  fontSize?: string;
}

interface CardProps extends WrapperProps {
  index: number;
  _id: string;
  question: string;
  answer: string;
  correctCount?: number | undefined;
  incorrectCount?: number | undefined;
  lastAnswered?: string;
  onDelete?: (_id: string) => void;
  countCorrect?: (_id: string, isCorrect: boolean) => void;
  showDelete?: boolean;
  showAnswer?: boolean;
  fetchPronunciation: (answer: string) => Promise<string | null>;
}

function Card({
  index,
  _id,
  question,
  answer,
  correctCount = 0,
  incorrectCount = 0,
  lastAnswered,
  onDelete,
  countCorrect,
  fetchPronunciation,
  height,
  fontSize,
  showDelete = true,
  showAnswer = false,
}: CardProps) {
  const [isShow, setIsShow] = useState(showAnswer);
  const [currentCorrectCount, setCurrentCorrectCount] = useState(correctCount);
  const [currentIncorrectCount, setCurrentIncorrectCount] = useState(incorrectCount);
  const [currentLastAnswered, setCurrentLastAnswered] = useState(lastAnswered || null);
  const [pronunciation, setPronunciation] = useState<string | null>(null);
  const setModal = useSetRecoilState(modalState);

  useEffect(() => {
    setCurrentCorrectCount(correctCount);
    setCurrentIncorrectCount(incorrectCount);
    setCurrentLastAnswered(lastAnswered || null);
  }, [correctCount, incorrectCount, lastAnswered]);

  const handleCorrectClick = async () => {
    if (countCorrect) {
      countCorrect(_id, true);
    }
    setIsShow(true);
    setCurrentCorrectCount(prev => prev + 1);
    setCurrentLastAnswered(new Date().toISOString());
    const fetchedPronunciation = await fetchPronunciation(answer);
    setPronunciation(fetchedPronunciation);
  };
  
  const handleIncorrectClick = async () => {
    if (countCorrect) {
      countCorrect(_id, false);
    }
    setIsShow(true);
    setCurrentIncorrectCount(prev => prev + 1);
    setCurrentLastAnswered(new Date().toISOString());
    const fetchedPronunciation = await fetchPronunciation(answer);
    setPronunciation(fetchedPronunciation);
  };

  const handleDeleteClick = () => {
    setModal({
      isOpen: true,
      title: "삭제 확인",
      message: "정말 삭제하시겠습니까? (삭제 시 복구가 불가능합니다.)",
      onConfirm: () => {
        if (onDelete) {
          onDelete(_id);
        }
        setModal((prev) => ({ ...prev, isOpen: false }));
      },
    });
  };

  const renderAnswer = () => {
    return (
      <>
        <AnswerWrapper>
          {answer.split("\n").map((line, index) => (
            <React.Fragment key={`answer-${index}`}>
              {line}<br />
            </React.Fragment>
          ))}
        </AnswerWrapper>
        {pronunciation && (
          <PronunciationWrapper>
            {pronunciation.split("\n").map((line, index) => (
              <React.Fragment key={`pron-${index}`}>
                {line}<br />
              </React.Fragment>
            ))}
          </PronunciationWrapper>
        )}
      </>
    );
  };
  
  return (
    <Wrapper height={height} fontSize={fontSize}>
      <TopBar>
        <IndexNumber>{index}</IndexNumber>
        {showDelete && (
          <FavoriteIconWrapper>
            <TrashIcon icon={faTrashAlt} onClick={handleDeleteClick} />
          </FavoriteIconWrapper>
        )}
      </TopBar>
      <ContentArea>
        <Question fontSize={fontSize}>
          {question.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
          {currentLastAnswered && (
            <InfoWrapper>
              <InfoItem>Last: {new Date(currentLastAnswered).toLocaleDateString()}</InfoItem>
              <Separator>·</Separator>
              <InfoItem>Count: {currentCorrectCount + currentIncorrectCount}</InfoItem>
            </InfoWrapper>
          )}
        </Question>
        {isShow || showAnswer ? (
          <Answer fontSize={fontSize}>
            {renderAnswer()}
          </Answer>
        ) : (
          <ButtonWrapper>
            <AnswerButton
              onClick={handleIncorrectClick}
              backgroundColor="#F44336"
              color="#ffffff"
              radius="0 0 0 10px"
            >
              <ButtonText>몰라</ButtonText>
              <KeyText>← 방향키</KeyText>
            </AnswerButton>
            <AnswerButton
              onClick={handleCorrectClick}
              backgroundColor="#4CAF50"
              color="#ffffff"
              radius="0 0 10px 0"
            >
              <ButtonText>생각났다!</ButtonText>
              <KeyText>방향키 →</KeyText>
            </AnswerButton>
          </ButtonWrapper>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default Card;