import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
`;

const ModalContent = styled.div`
  background-color: #1e1f21;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  max-width: 90%;
`;

const ModalTitle = styled.h2`
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  color: #ffffff;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-left: 10px;
  transition: background-color 0.3s, color 0.3s;
  
  ${props => props.primary ? `
    background-color: #01e795;
    color: #000000;
    &:hover {
      background-color: #00c278;
    }
  ` : `
    background-color: #3a3b3d;
    color: #ffffff;
    &:hover {
      background-color: #4a4b4d;
    }
  `}
`;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  onConfirm?: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, message, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalText>{message}</ModalText>
        <ButtonContainer>
          <Button onClick={onClose}>닫기</Button>
          {onConfirm && <Button primary onClick={onConfirm}>확인</Button>}
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;