import styled from "styled-components";
import { useState } from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";

const Wrapper = styled.section`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  display: flex;
  justify-content: center;
  min-height: 600px;
`;

const SubWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 30px;
  display: flex;
  justify-content: center;
`;

const IdWrapper = styled.div`
  height: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  text-align: center;
`;

function Sign() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");

  const handleBack = () => {
    navigate("/login");
  };

  const handleSignUp = async () => {
    try {
      const response = await fetch("http://158.247.215.166:5000/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error("Sign up failed");
      }
      setModalTitle("회원가입 성공");
      setModalMessage("회원가입이 성공적으로 완료되었습니다.");
      setIsModalOpen(true);
    } catch (error) {
      console.error("Sign up error:", error);
      setModalTitle("회원가입 실패");
      setModalMessage("이미 존재하는 아이디입니다.");
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (modalTitle === "회원가입 성공") {
      navigate("/login");
    }
  };

  return (
    <>
      <Wrapper>
        <SubWrapper>
          <Title>회원가입</Title>
          <IdWrapper>
            <Input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="아이디를 입력하세요"
            />
          </IdWrapper>
          <IdWrapper>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력하세요"
            />
          </IdWrapper>
          <IdWrapper>
            <Button
              bgcolor="#FA5882"
              width="45%"
              height="100%"
              onClick={handleSignUp}
            >
              회원가입
            </Button>
            <Button
              bgcolor="#F7819F"
              width="45%"
              height="100%"
              onClick={handleBack}
            >
              취소
            </Button>
          </IdWrapper>
        </SubWrapper>
      </Wrapper>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={modalTitle}
        message={modalMessage}
      />
    </>
  );
}

export default Sign;