import { useRecoilValue } from "recoil";
import { authState } from "../recoil/authState";

const BASE_URL = "http://158.247.215.166:5000";

export const useAPI = () => {
  const auth = useRecoilValue(authState);

  const getTokenHeader = () => {
    const token = localStorage.getItem('token') || auth.token;
    console.log('Using token:', token);
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  };

  const fetchQnA = async () => {
    try {
      const response = await fetch(`${BASE_URL}/qna`, {
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error fetching QnA:", error);
      return [];
    }
  };

  const addQnA = async (question, answer) => {
    try {
      const response = await fetch(`${BASE_URL}/qna`, {
        method: "POST",
        headers: getTokenHeader(),
        body: JSON.stringify({ question, answer }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add QnA");
      }
      const data = await response.json();
      console.log("Server response for single QnA add:", data);
      return data;
    } catch (error) {
      console.error("Error adding QnA:", error);
      throw error;
    }
  };

  const bulkAddQnA = async (qnaList) => {
    try {
      console.log("Sending to server:", qnaList);
      const response = await fetch(`${BASE_URL}/qna/bulk`, {
        method: "POST",
        headers: getTokenHeader(),
        body: JSON.stringify({ qnaList }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add QnAs in bulk");
      }
      const data = await response.json();
      console.log("Server response:", data);
      return data;
    } catch (error) {
      console.error("Error adding QnAs in bulk:", error);
      throw error;
    }
  };

  const deleteQnA = async (_id) => {
    try {
      await fetch(`${BASE_URL}/qna/${_id}`, {
        method: "DELETE",
        headers: getTokenHeader(),
      });
    } catch (error) {
      console.error("Error deleting QnA:", error);
    }
  };

  const updateQnACount = async (_id, isCorrect) => {
    try {
      await fetch(`${BASE_URL}/qna/count/${_id}`, {
        method: "POST",
        headers: getTokenHeader(),
        body: JSON.stringify({ isCorrect }),
      });
    } catch (error) {
      console.error("Error updating QnA count:", error);
    }
  };

  const saveQnA = async (_id, isSaved) => {
    try {
      const response = await fetch(`${BASE_URL}/qna/save/${_id}`, {
        method: "PATCH",
        headers: getTokenHeader(),
        body: JSON.stringify({ isSaved }),
      });
      return await response.json();
    } catch (error) {
      console.error("Error saving QnA:", error);
    }
  };

  const updateLastAnswered = async (_id) => {
    try {
      const response = await fetch(`${BASE_URL}/qna/lastAnswered/${_id}`, {
        method: "PATCH",
        headers: getTokenHeader(),
      });
      return await response.json();
    } catch (error) {
      console.error("Error updating last answered time:", error);
    }
  };

  const fetchRankings = async () => {
    try {
      const response = await fetch(`${BASE_URL}/ranking`, {
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error fetching rankings:", error);
      return [];
    }
  };

  const deleteAllQnA = async () => {
    try {
      const response = await fetch(`${BASE_URL}/qna-delete-all`, {
        method: "DELETE",
        headers: getTokenHeader(),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete all QnAs");
      }
      return await response.json();
    } catch (error) {
      console.error("Error deleting all QnAs:", error);
      throw error;
    }
  };

  const fetchPronunciation = async (answer) => {
    try {
      console.log('Fetching pronunciation for:', answer);
      const response = await fetch(`${BASE_URL}/pronunciation/${encodeURIComponent(answer)}`, {
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      console.log('Received pronunciation:', data.pronunciation);
      return data.pronunciation;
    } catch (error) {
      console.error("Error fetching pronunciation:", error);
      return null;
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users`, {
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error fetching users:", error);
      return [];
    }
  };
  
  const deleteUser = async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/users/${userId}`, {
        method: "DELETE",
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  };
  
  const fetchUserQnAs = async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/qna/user/${userId}`, {
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error fetching user QnAs:", error);
      return [];
    }
  };
  
  const deleteUserQnAs = async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/qna/user/${userId}`, {
        method: "DELETE",
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error deleting user QnAs:", error);
      throw error;
    }
  };
  
  const resetUserCounts = async (userId) => {
    try {
      const response = await fetch(`${BASE_URL}/reset-user-counts/${userId}`, {
        method: "POST",
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error resetting user counts:", error);
      throw error;
    }
  };
  
  const fetchPronunciations = async (page = 1, limit = 50) => {
    try {
      const response = await fetch(`${BASE_URL}/pronunciations?page=${page}&limit=${limit}`, {
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error fetching pronunciations:", error);
      return { pronunciations: [], currentPage: 1, totalPages: 1, totalItems: 0 };
    }
  };
  
  const deletePronunciation = async (word) => {
    try {
      const response = await fetch(`${BASE_URL}/pronunciations/${encodeURIComponent(word)}`, {
        method: "DELETE",
        headers: getTokenHeader(),
      });
      if (!response.ok) throw new Error("Network response was not ok");
      return await response.json();
    } catch (error) {
      console.error("Error deleting pronunciation:", error);
      throw error;
    }
  };
  
  return {
    fetchQnA,
    addQnA,
    bulkAddQnA,
    deleteQnA,
    updateQnACount,
    saveQnA,
    updateLastAnswered,
    fetchRankings,
    deleteAllQnA,
    fetchPronunciation,
    fetchUsers,
    deleteUser,
    fetchUserQnAs,
    deleteUserQnAs,
    resetUserCounts,
    fetchPronunciations,
    deletePronunciation
  };
  };