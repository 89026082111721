import styled from "styled-components";
import { useState, useEffect } from "react";
import Button from "./Button";
import { useSetRecoilState } from "recoil";
import { authState, createUser } from "./authState";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.section`
  width: 100%;
  max-width: 1000px;
  height: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
`;

const SubWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 500px) {
    width: 70%;
  }
`;

const Title = styled.div`
  font-size: 30px;
  display: flex;
  justify-content: center;
  height: 10%;
`;

const IdWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  text-align: center;
`;

const SaveId = styled.div`
  font-size: 0.8rem;
`;

const SaveIdWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const CheckBox = styled.input`
  width: 20px;
  height: 20px;
`;

function LogIn() {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authState);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // 컴포넌트가 마운트될 때 localStorage에서 저장된 아이디를 불러옴
  useEffect(() => {
    const rememberedUsername = localStorage.getItem("rememberedUsername");
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setIsRemember(true); // 체크박스도 체크된 상태로 설정
    }
  }, []);

  const handleMoveSign = () => {
    navigate("/sign");
  };

  const handleLogin = async () => {
    try {
      const response = await fetch("http://158.247.215.166:5000/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed");
      }

      const { token } = await response.json();
      
      localStorage.setItem('token', token);
      
      const user = createUser(username);
      
      setAuth({
        isAuthenticated: true,
        user,
        token: token,
      });

      if (isRemember) {
        localStorage.setItem("rememberedUsername", username);
      } else {
        localStorage.removeItem("rememberedUsername");
      }

      navigate("/");
    } catch (error: any) {
      setErrorMessage(error.message);
      alert("아이디 또는 비밀번호가 일치하지 않습니다.");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <Wrapper>
      <SubWrapper>
        <Title>암기장 로그인</Title>
        <IdWrapper>
          <Input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="아이디를 입력하세요"
            onKeyDown={handleKeyDown}
          />
        </IdWrapper>
        <IdWrapper>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="비밀번호를 입력하세요"
            onKeyDown={handleKeyDown}
          />
        </IdWrapper>
        <CheckBoxWrapper>
            <SaveId onClick={handleMoveSign}>회원가입</SaveId>
          <SaveIdWrapper>
            <SaveId>아이디 저장</SaveId>
            <CheckBox
              checked={isRemember}
              onChange={(e) => setIsRemember(e.target.checked)}
              type="checkbox"
            />
          </SaveIdWrapper>
        </CheckBoxWrapper>
        <IdWrapper>
          <Button
            onClick={handleLogin}
            bgcolor="#FA5882"
            width="100%"
            height="100%"
          >
            로그인
          </Button>
        </IdWrapper>
      </SubWrapper>
    </Wrapper>
  );
}

export default LogIn;
