import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #1e1f21;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
`;

const ModalTitle = styled.h2`
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #3a3b3d;
  border-radius: 10px;
  overflow: hidden;
`;

const Progress = styled.div<{ width: number }>`
  width: ${props => props.width}%;
  height: 100%;
  background-color: #01e795;
  transition: width 0.3s ease;
`;

const ProgressText = styled.p`
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c0392b;
  }
`;

interface ProgressModalProps {
  isOpen: boolean;
  progress: number;
  onCancel: () => void;
}

const ProgressModal: React.FC<ProgressModalProps> = ({ isOpen, progress, onCancel }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalTitle>파일 업로드 중...</ModalTitle>
        <ProgressBar>
          <Progress width={progress} />
        </ProgressBar>
        <ProgressText>{progress}% 완료</ProgressText>
        <CancelButton onClick={onCancel}>취소</CancelButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ProgressModal;