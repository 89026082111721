import React from 'react';
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../recoil/authState";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const auth = useRecoilValue(authState);

  if (!auth.isAuthenticated) {
    // 로그인하지 않은 경우 로그인 페이지로 리디렉션
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;