// src/states/authState.ts
import { atom } from "recoil";

export interface User {
  id: string;
  username: string;
  // 필요에 따라 추가 필드를 정의할 수 있습니다.
}

interface AuthState {
  isAuthenticated: boolean | null;
  user: User | null;
  token: string | null;
}

export const authState = atom<AuthState>({
  key: "authState",
  default: {
    isAuthenticated: false,
    user: null,
    token: null,
  },
});
