import "./App.css";
import styled from "styled-components";
import MainPage from "./components/MainPage";
import LogIn from "./components/LogIn";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Sign from "./components/Sign";
import { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { authState } from "./recoil/authState";
import { modalState } from "./recoil/modalState";
import Modal from "./components/Modal";

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

function App() {
  const setAuth = useSetRecoilState(authState);
  const modal = useRecoilValue(modalState);
  const setModal = useSetRecoilState(modalState);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuth({
        isAuthenticated: true,
        token: token,
        user: null,
      });
    }
  }, [setAuth]);

  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route path="/login" element={<LogIn />} />
          <Route path="/sign" element={<Sign />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Wrapper>
      <Modal
        isOpen={modal.isOpen}
        onClose={() => setModal({ ...modal, isOpen: false })}
        title={modal.title}
        message={modal.message}
        onConfirm={modal.onConfirm}
      />
    </Router>
  );
}

export default App;