import React, { useState, useRef } from "react";
import styled from "styled-components";
import Button from "./Button";
import { useAPI } from "./API";
import Modal from "./Modal";
import ProgressModal from "./ProgressModal";

const ListWrapper = styled.div`
  height: 95%;
  background-color: #0e0f10;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const InputMainWrapper = styled.div`
  width: 100%;
  height: 250px;
  border-radius: 10px 10px 0 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InputWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

const QuestionInput = styled.textarea`
  padding: 10px;
  width: 48%;
  height: 100%;
  resize: none;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
`;

const AnswerInput = styled.textarea`
  padding: 10px;
  width: 48%;
  height: 100%;
  resize: none;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 14px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
  border-radius: 0 0 10px 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #01e795;
  color: #0e0f10;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #00c278;
  }
`;

const CSVUploadButton = styled(StyledButton)`
  height: 70px;
  border-radius: 0;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

interface QnAAddProps {
  onAddSuccess: () => void;
  onBulkAddQnA: () => void;
}

function QnAAdd({ onAddSuccess, onBulkAddQnA }: QnAAddProps) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { addQnA, bulkAddQnA } = useAPI();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCancelled, setIsCancelled] = useState(false);

  const handleAdd = async () => {
    if (!question.trim() || !answer.trim()) {
      setModalTitle("입력 오류");
      setModalMessage("질문과 답변을 모두 입력해주세요.");
      setIsModalOpen(true);
      return;
    }
    try {
      const newQnA = await addQnA(question, answer);
      setModalTitle("성공");
      setModalMessage("문답이 성공적으로 추가되었습니다.");
      setIsModalOpen(true);
      setQuestion("");
      setAnswer("");
      onAddSuccess();
    } catch (error) {
      console.error("Error adding QnA:", error);
      setModalTitle("오류");
      setModalMessage(`문답 추가 중 오류가 발생했습니다: ${error instanceof Error ? error.message : '알 수 없는 오류'}`);
      setIsModalOpen(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();
      handleAdd();
    }
  };

  const handleCSVUpload = () => {
    fileInputRef.current?.click();
  };

  const parseCSVLine = (line: string): [string, string] | null => {
    const regex = /(?:^|,)(?:"([^"]*(?:""[^"]*)*)"|([^,]*))/g;
    const result: string[] = [];
    let match;
    
    while ((match = regex.exec(line)) !== null) {
      if (match[1] !== undefined) {
        result.push(match[1].replace(/""/g, '"'));
      } else {
        result.push(match[2]);
      }
    }
  
    if (result.length >= 2) {
      return [
        result[0].trim().replace(/\\n/g, '\n'),
        result.slice(1).join(',').trim().replace(/\\n/g, '\n')
      ];
    }
    return null;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target?.result;
        if (typeof text === 'string') {
          const rows = text.split('\n');
          const qnaList = rows
            .map(parseCSVLine)
            .filter((item): item is [string, string] => item !== null);

          setIsProgressModalOpen(true);
          setIsCancelled(false);
          try {
            const totalItems = qnaList.length;
            let processedItems = 0;

            for (const [question, answer] of qnaList) {
              if (isCancelled) break;
              await bulkAddQnA([{ question, answer }]);
              processedItems++;
              setProgress(Math.round((processedItems / totalItems) * 100));
            }

            if (!isCancelled) {
              setModalTitle("성공");
              setModalMessage(`CSV 파일이 성공적으로 업로드되었습니다. 
                추가된 문답: ${processedItems}`);
              onBulkAddQnA();
            }
          } catch (error) {
            console.error("Error uploading CSV:", error);
            setModalTitle("오류");
            setModalMessage("CSV 파일 업로드 중 오류가 발생했습니다.");
          } finally {
            setIsProgressModalOpen(false);
            if (!isCancelled) {
              setIsModalOpen(true);
            }
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const handleCancel = () => {
    setIsCancelled(true);
  };

  return (
    <ListWrapper>
      <ContentWrapper>
        <InputMainWrapper>
          <InputWrapper>
            <QuestionInput
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="질문을 입력하세요."
              onKeyDown={handleKeyDown}
            />
            <AnswerInput
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              placeholder="답변을 입력하세요."
              onKeyDown={handleKeyDown}
            />
          </InputWrapper>
        </InputMainWrapper>
        <StyledButton onClick={handleAdd}>
          추가
        </StyledButton>
      </ContentWrapper>
      <CSVUploadButton onClick={handleCSVUpload}>
        CSV 파일 업로드
      </CSVUploadButton>
      <HiddenFileInput
        type="file"
        accept=".csv"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalTitle}
        message={modalMessage}
      />
      <ProgressModal
        isOpen={isProgressModalOpen}
        progress={progress}
        onCancel={handleCancel}
      />
    </ListWrapper>
  );
}

export default QnAAdd;