import React from "react";
import styled from "styled-components";
import Card from "./Card";
import { useAPI } from "./API";
import { useSetRecoilState } from "recoil";
import { modalState } from "../recoil/modalState";

const ListWrapper = styled.div`
  height: 95%;
  background-color: #0e0f10;
  display: flex;
  flex-direction: column;
`;

const CardListWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  position: relative;

  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const CardContainer = styled.div`
  margin-bottom: 10px; // 각 카드 사이에 10px 간격 추가
`;

const DeleteAllButton = styled.button`
  width: 30%;
  background-color: #e74c3c;
  position: sticky;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 10;
  &:hover {
    background-color: #c0392b;
  }
`;

const EmptyList = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

interface QnAItem {
  _id: string;
  correctCount?: number;
  incorrectCount?: number;
  question: string;
  answer: string;
  lastAnswered?: string;
}

interface QnAListProps {
  qnaList: QnAItem[];
  onDelete: (_id: string) => void;
  countCorrect: (_id: string, isCorrect: boolean) => void;
  refreshList: () => void;
}

function QnAList({ qnaList, onDelete, countCorrect, refreshList }: QnAListProps) {
  const { deleteAllQnA, fetchPronunciation } = useAPI();
  const setModal = useSetRecoilState(modalState);

  const handleDeleteAll = () => {
    setModal({
      isOpen: true,
      title: "전체 삭제 확인",
      message: "모든 문답을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
      onConfirm: confirmDeleteAll,
    });
  };

  const confirmDeleteAll = async () => {
    try {
      await deleteAllQnA();
      refreshList();
    } catch (error) {
      console.error("Error deleting all QnAs:", error);
    }
    setModal((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <ListWrapper>
      <CardListWrapper>
        <DeleteAllButton onClick={handleDeleteAll}>전체 삭제</DeleteAllButton>
        {qnaList.length === 0 ? (
          <EmptyList>문답 데이터를 추가해주세요.</EmptyList>
        ) : (
          qnaList.map((qna: QnAItem, index) => (
            <CardContainer key={qna._id}>
              <Card
                _id={qna._id}
                onDelete={() => onDelete(qna._id)}
                incorrectCount={qna.incorrectCount}
                correctCount={qna.correctCount}
                question={qna.question}
                answer={qna.answer}
                fetchPronunciation={fetchPronunciation}
                countCorrect={countCorrect}
                height="200px"
                lastAnswered={qna.lastAnswered}
                index={index + 1}
                showDelete={true}
                showAnswer={true}
                fontSize="12px"
              />
            </CardContainer>
          ))
        )}
      </CardListWrapper>
    </ListWrapper>
  );
}

export default QnAList;