import { atom } from 'recoil';

interface ModalState {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm?: () => void;
}

export const modalState = atom<ModalState>({
  key: 'modalState',
  default: {
    isOpen: false,
    title: '',
    message: '',
    onConfirm: undefined,
  },
});