import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAPI } from './API';

const AdminWrapper = styled.div`
  background-color: #0e0f10;
  padding: 20px;
  height: 95%;
  overflow-y: auto;
  h2 {
    color: #ffffff;
  }
`;

const DataList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const DataItem = styled.li`
  background-color: #2e2f31;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff; 
  * {
    color: inherit;
  }
`;

const Button = styled.button`
  background-color: #01e795;
  color: #0e0f10;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;

  &:hover {
    background-color: #00c278;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #ff4136;
  color: white;

  &:hover {
    background-color: #ff1a1a;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #2e2f31;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  color: #e0e0e0;
`;

const ConfirmModal = styled(Modal)``;

const ConfirmModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResetButton = styled(Button)`
  background-color: #f39c12;
  color: white;

  &:hover {
    background-color: #e67e22;
  }
`;

const PronunciationItem = styled(DataItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled(Button)`
  margin: 0 5px;
`;

interface User {
  _id: string;
  username: string;
  todayAnswered: number;
  totalAnswered: number;
  lastResetDate: string;
  cardCount: number;
}

interface QnA {
  _id: string;
  user: string;
  question: string;
  answer: string;
  correctCount: number;
  incorrectCount: number;
  isSaved: boolean;
  lastAnswered: string;
}

interface Pronunciation {
  word: string;
  pronunciation: string;
}

function AdminPanel() {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserQnAs, setSelectedUserQnAs] = useState<QnA[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState<() => void>(() => {});
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showPronunciations, setShowPronunciations] = useState(false);
  const [pronunciations, setPronunciations] = useState<Pronunciation[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { 
    fetchUsers, 
    deleteUser, 
    fetchUserQnAs, 
    deleteUserQnAs, 
    resetUserCounts,
    fetchPronunciations,
    deletePronunciation
  } = useAPI();

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const fetchedUsers = await fetchUsers();
      setUsers(fetchedUsers);
    } catch (error) {
      console.error('Error loading users:', error);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    setConfirmMessage('Are you sure you want to delete this user? This action cannot be undone.');
    setConfirmAction(() => async () => {
      try {
        await deleteUser(userId);
        loadUsers();
        setIsConfirmModalOpen(false);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    });
    setIsConfirmModalOpen(true);
  };

  const handleViewQnAs = async (userId: string) => {
    try {
      const userQnAs = await fetchUserQnAs(userId);
      setSelectedUserQnAs(userQnAs);
      setSelectedUserId(userId);
    } catch (error) {
      console.error('Error fetching user QnAs:', error);
    }
  };

  const handleDeleteUserQnAs = async (userId: string) => {
    setConfirmMessage('Are you sure you want to delete all QnAs for this user? This action cannot be undone.');
    setConfirmAction(() => async () => {
      try {
        await deleteUserQnAs(userId);
        setSelectedUserQnAs([]);
        loadUsers();
        setIsConfirmModalOpen(false);
      } catch (error) {
        console.error('Error deleting user QnAs:', error);
      }
    });
    setIsConfirmModalOpen(true);
  };

  const handleResetUserCounts = async (userId: string) => {
    setConfirmMessage('Are you sure you want to reset all counts for this user? This action cannot be undone.');
    setConfirmAction(() => async () => {
      try {
        await resetUserCounts(userId);
        loadUsers();
        setIsConfirmModalOpen(false);
      } catch (error) {
        console.error('Error resetting user counts:', error);
      }
    });
    setIsConfirmModalOpen(true);
  };

  const loadPronunciations = async (page = 1) => {
    try {
      const data = await fetchPronunciations(page);
      setPronunciations(data.pronunciations);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error loading pronunciations:', error);
    }
  };

  const handleDeletePronunciation = async (word: string) => {
    setConfirmMessage(`Are you sure you want to delete the pronunciation for "${word}"?`);
    setConfirmAction(() => async () => {
      try {
        await deletePronunciation(word);
        loadPronunciations(currentPage);
        setIsConfirmModalOpen(false);
      } catch (error) {
        console.error('Error deleting pronunciation:', error);
      }
    });
    setIsConfirmModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUserQnAs([]);
    setSelectedUserId(null);
  };

  return (
    <AdminWrapper>
      <h2>User Management</h2>
      <DataList>
        {users.map((user) => (
          <DataItem key={user._id}>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>User ID:</strong> {user._id}</p>
            <p><strong>Today's Answered:</strong> {user.todayAnswered}</p>
            <p><strong>Total Answered:</strong> {user.totalAnswered}</p>
            <p><strong>Last Reset Date:</strong> {new Date(user.lastResetDate).toLocaleString()}</p>
            <p><strong>Card Count:</strong> {user.cardCount}</p>
            <Button onClick={() => handleViewQnAs(user._id)}>View QnAs</Button>
            <DeleteButton onClick={() => handleDeleteUser(user._id)}>Delete User</DeleteButton>
            <ResetButton onClick={() => handleResetUserCounts(user._id)}>Reset Counts</ResetButton>
          </DataItem>
        ))}
      </DataList>

      <h2>Pronunciation Management</h2>
      <Button onClick={() => {
        setShowPronunciations(!showPronunciations);
        if (!showPronunciations) {
          loadPronunciations();
        }
      }}>
        {showPronunciations ? 'Hide Pronunciations' : 'Show Pronunciations'}
      </Button>

      {showPronunciations && (
        <>
          <DataList>
            {pronunciations.map((item) => (
              <PronunciationItem key={item.word}>
                <span><strong>Word:</strong> {item.word}</span>
                <span><strong>Pronunciation:</strong> {item.pronunciation}</span>
                <DeleteButton onClick={() => handleDeletePronunciation(item.word)}>Delete</DeleteButton>
              </PronunciationItem>
            ))}
          </DataList>
          <PaginationControls>
            <PageButton 
              onClick={() => loadPronunciations(currentPage - 1)} 
              disabled={currentPage === 1}
            >
              Previous
            </PageButton>
            <span>Page {currentPage} of {totalPages}</span>
            <PageButton 
              onClick={() => loadPronunciations(currentPage + 1)} 
              disabled={currentPage === totalPages}
            >
              Next
            </PageButton>
          </PaginationControls>
        </>
      )}

      {selectedUserQnAs.length > 0 && (
        <Modal onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h3>User QnAs</h3>
            <DeleteButton onClick={() => selectedUserId && handleDeleteUserQnAs(selectedUserId)}>
              Delete All User QnAs
            </DeleteButton>
            <DataList>
              {selectedUserQnAs.map((qna) => (
                <DataItem key={qna._id}>
                  <p><strong>Question:</strong> {qna.question}</p>
                  <p><strong>Answer:</strong> {qna.answer}</p>
                  <p><strong>Correct Count:</strong> {qna.correctCount}</p>
                  <p><strong>Incorrect Count:</strong> {qna.incorrectCount}</p>
                  <p><strong>Saved:</strong> {qna.isSaved ? 'Yes' : 'No'}</p>
                  <p><strong>Last Answered:</strong> {qna.lastAnswered ? new Date(qna.lastAnswered).toLocaleString() : 'Never'}</p>
                </DataItem>
              ))}
            </DataList>
            <Button onClick={closeModal}>Close</Button>
          </ModalContent>
        </Modal>
      )}

      {isConfirmModalOpen && (
        <ConfirmModal>
          <ConfirmModalContent>
            <p>{confirmMessage}</p>
            <Button onClick={confirmAction}>Confirm</Button>
            <Button onClick={() => setIsConfirmModalOpen(false)}>Cancel</Button>
          </ConfirmModalContent>
        </ConfirmModal>
      )}
    </AdminWrapper>
  );
}

export default AdminPanel;