import { atom } from 'recoil';

export interface User {
  id?: string;
  username: string;
}

export const createUser = (username: string, id?: string): User => ({
  username,
  ...(id && { id })
});

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    isAuthenticated: false,
    user: null,
    token: null,
  },
  effects: [
    ({ setSelf, onSet }) => {
      const savedToken = localStorage.getItem('token');
      if (savedToken) {
        setSelf({
          isAuthenticated: true,
          user: null,
          token: savedToken,
        });
      }

      onSet((newValue, _, isReset) => {
        if (!isReset) {
          localStorage.setItem('token', newValue.token || '');
        }
      });
    },
  ],
});